exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contacts-js": () => import("./../../../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-for-business-js": () => import("./../../../src/pages/for-business.js" /* webpackChunkName: "component---src-pages-for-business-js" */),
  "component---src-pages-for-private-js": () => import("./../../../src/pages/for-private.js" /* webpackChunkName: "component---src-pages-for-private-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-instructions-js": () => import("./../../../src/pages/instructions.js" /* webpackChunkName: "component---src-pages-instructions-js" */),
  "component---src-pages-privacy-bank-js": () => import("./../../../src/pages/privacy-bank.js" /* webpackChunkName: "component---src-pages-privacy-bank-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-promo-2021-js": () => import("./../../../src/pages/promo2021.js" /* webpackChunkName: "component---src-pages-promo-2021-js" */),
  "component---src-pages-questions-and-answers-js": () => import("./../../../src/pages/questions-and-answers.js" /* webpackChunkName: "component---src-pages-questions-and-answers-js" */),
  "component---src-pages-reviews-js": () => import("./../../../src/pages/reviews.js" /* webpackChunkName: "component---src-pages-reviews-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-sewers-js": () => import("./../../../src/pages/sewers.js" /* webpackChunkName: "component---src-pages-sewers-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */)
}

