/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "./src/static/fonts/webfonts/Montserrat.css"
import "./src/styles/global.css"

export function onRouteUpdate(args, pluginOptions) {
  if (
    (process.env.NODE_ENV === `production` ||
      pluginOptions.includeInDevelopment) &&
    typeof window.VK !== `undefined` &&
    typeof window.VK.Retargeting !== `undefined` &&
    typeof window.VK.Retargeting.Hit === `function`
  ) {
    window.VK.Retargeting.Hit()
  }
}
